const schema = {
    product_id: 1,
    properties: [
        // {
        //     property_id: 1,
        //     property_name: "Kiểu Lát",
        //     values: [
        //         "Lát xương cá", "Lát thẳng"
        //     ]
        // },
        // {
        //     property_id: 2,
        //     property_name: "Sàn Gỗ",
        //     values: [
        //         "tự nhiên nguyên khối", "olefin kỹ thuật"
        //     ]
        // }
    ]
}

export { schema };