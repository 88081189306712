import styled from "styled-components";

export const BoxThumbnail = styled.a`
    width: 50px;
    height: 50px;
    display: block;
    overflow: hidden;
    border-radius: 5px;
    img{
        width: 100%;
    }
`;