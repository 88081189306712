import React from "react";
import RouterPage from "./RouterPage";
import "./app.scss";


function App() {
    return (<div id="wrapper">
        <RouterPage />
    </div>)
}

export default App;